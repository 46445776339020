












import { Component, Vue, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import { MessageText } from '@/services/data'
import { ElForm } from 'element-ui/types/form'

@Component
export default class NumberRowSelector extends Vue {
  $refs: {
    textForm: ElForm;
  }

  mounted () {
    this.formValue.inputValue = this.value
  }

  formValue: { inputValue: number } = { inputValue: 0 }
  message: MessageText = null
  @Model('input', { type: Number }) value: number
  // do not put default value to prop value
  @Prop() rules: any
  @Prop() items: Array<number> | number

  // @Emit('input')
  handleChange () {
    this.$emit('input', this.formValue.inputValue)
  }

  @Watch('value')
  onValueChanged (v: number) {
    this.formValue.inputValue = v
  }

  @Watch('formValue.inputValue')
  onInputValueChanged (v: number) {
    this.$emit('input', v)
  }

  // if use @Emit,do not use this.$emit again, @Emit will return the function value to parent
  submit () {
    this.$refs.textForm.validate((valid: boolean) => {
      if (valid) {
        this.$emit('change')
      }
    })
  }
}
